/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
const $ = require('jquery');

global.$ = global.jQuery = $;

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './styles/bootstrap.min.css';
import './styles/flexslider.css';
import './styles/main.css';
import './styles/responsive-tables.css';
// start the Stimulus application
import './bootstrap';
